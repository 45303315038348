import React, { FunctionComponent, useRef, useState } from 'react'
import { Slide } from '../../../Core/components/SlidesShow/Slide'
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo'
import 'react-html5-camera-photo/build/css/index.css'
import { Icon, Icons } from '../../../Core/components/Icon/Icon'
import { Button, ButtonType } from '../../../Core/components/Button/Button'
import styles from './PhotoSlide.module.scss'
import { Row } from '../../../Core/components/Layout/Row/Row'

interface Props {
    isActive?: boolean
    isDisable?: boolean
    onTakePhoto?: (dataUri?: string) => void
}

export const PhotoSlide: FunctionComponent<Props> = ({ isActive = false, isDisable = false, onTakePhoto }) => {
    const [isCameraActive, setIsCameraActive] = useState(false)
    const [dataUri, setDataUri] = useState<string>()

    const [isCameraError, setIsCameraError] = useState(false)
    const fileUploader = useRef<HTMLInputElement>(null)

    const getBase64 = (file: File, cb: (bStr: string) => void) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
            if (reader.result && typeof reader.result === 'string') {
                cb(reader.result)
            }
        }
    }

    const handleChangeImage = (evt: React.ChangeEvent<HTMLInputElement>) => {
        if (dataUri) {
            return
        }

        const fileList = evt.target?.files
        const file = fileList && fileList[0]

        if (file == null) {
            return
        }

        getBase64(file, (result: string) => {
            setDataUri(result)
            onTakePhoto && onTakePhoto(result)
        })
    }

    return (
        <>
            <Slide isActive={isActive}>
                {isCameraActive && (
                    <Button
                        className={styles.close}
                        type={ButtonType.noStyling}
                        onClick={() => {
                            setIsCameraActive(false)
                        }}
                    >
                        <Icon icon={Icons.close} size={[20, 20]} />
                    </Button>
                )}
                {dataUri && (
                    <Button
                        disabled={isDisable}
                        className={styles.close}
                        type={ButtonType.noStyling}
                        onClick={() => {
                            setDataUri(undefined)
                            onTakePhoto && onTakePhoto(undefined)
                        }}
                    >
                        <Icon icon={Icons.close} size={[20, 20]} />
                    </Button>
                )}
                {isCameraActive && (
                    <Camera
                        onTakePhotoAnimationDone={dataUri => {
                            setDataUri(dataUri)
                            setIsCameraActive(false)
                            onTakePhoto && onTakePhoto(dataUri)
                        }}
                        idealFacingMode={FACING_MODES.ENVIRONMENT}
                        isDisplayStartCameraError={false}
                        idealResolution={{ width: 640, height: 480 }}
                        isImageMirror={false}
                        imageType={IMAGE_TYPES.JPG}
                        onCameraError={() => {
                            setIsCameraError(true)
                            setIsCameraActive(false)
                        }}
                    />
                )}
                {!isCameraActive && !dataUri && (
                    <Row className={styles.row}>
                        {!isCameraError && (
                            <Button
                                type={ButtonType.noStyling}
                                className={styles.photoButton}
                                onClick={() => {
                                    setIsCameraActive(true)
                                }}
                            >
                                <Icon icon={Icons.photo} />
                            </Button>
                        )}
                        <Button
                            type={ButtonType.noStyling}
                            className={styles.photoButton}
                            onClick={() => {
                                fileUploader.current?.click()
                            }}
                        >
                            <Icon icon={Icons.img} />
                        </Button>
                    </Row>
                )}
                {dataUri && <img className={styles.image} src={dataUri} alt={'The backside of a sensor'} />}
            </Slide>
            {!dataUri && (
                <input
                    type="file"
                    id="file"
                    ref={fileUploader}
                    style={{ display: 'none' }}
                    onChange={handleChangeImage}
                />
            )}
        </>
    )
}
