import { ActivityIndicator, ActivityIndicatorEnumType } from '@unicaiot/unica-iot-gallery-core'
import React, { FunctionComponent } from 'react'
import { Slide } from '../../../Core/components/SlidesShow/Slide'
import { useGet } from '../../services/hooks'
import { QRMapOverview, QRMapOverviewHeader, UnknownOverview } from '../QRMapOverview/QRMapOverview'
import styles from './ListSlide.module.scss'

interface Props {
    isActive?: boolean
}

export const ListSlide: FunctionComponent<Props> = ({ isActive = false }) => {
    const gResult = useGet()

    return (
        <Slide isActive={isActive}>
            {gResult.loading ? (
                <ActivityIndicator className={styles.loader} size={ActivityIndicatorEnumType.large} />
            ) : (
                <div className={styles.slide}>
                    <QRMapOverviewHeader />
                    {gResult.data?.map((t, i) => {
                        return t.parameters ? <QRMapOverview item={t} key={i} /> : <UnknownOverview item={t} key={i} />
                    })}
                </div>
            )}
        </Slide>
    )
}
