import React, { FunctionComponent } from 'react'
import styles from './QRMapOverview.module.scss'
import { QRMap as QRMapType } from '../../services/types'
import { Row } from '../../../Core/components/Layout/Row/Row'
import classNames from 'classnames'

export interface QRMapOverviewProps {
    item: QRMapType
}

export const QRMapOverview: FunctionComponent<QRMapOverviewProps> = ({ item }) => {
    return (
        <Row className={classNames(styles.container)}>
            <div className={styles.code}>{item.parameters?.code || 'N/A'}</div>
            <div className={styles.eui}>{item.parameters?.devEUI || 'N/A'}</div>
        </Row>
    )
}

export const UnknownOverview: FunctionComponent<QRMapOverviewProps> = ({ item }) => {
    return (
        <Row className={classNames(styles.container)}>
            <div className={styles.ref}>{item.value}</div>
        </Row>
    )
}

export const QRMapOverviewHeader: FunctionComponent = () => {
    return (
        <Row className={styles.headerContainer}>
            <div className={styles.code}>Code</div>
            <div className={styles.eui}>DevEUI</div>
        </Row>
    )
}
