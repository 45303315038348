import styles from './Body.module.scss'

import React from 'react'

import classNames, { Value } from 'classnames' // eslint-disable-next-line

interface Props {
    className?: Value
}

export const Body: React.FunctionComponent<Props> = ({ className, children }) => {
    return <div className={classNames(styles.container, className)}>{children}</div>
}
