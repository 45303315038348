import React, { FunctionComponent, useState } from 'react'
import { Page } from '../../Core/components/Layout/Page/Page'
import { RouteComponentProps } from 'react-router-dom'
import { Body } from '../../Core/components/Layout/Body/Body'
import { Auth } from '../../Auth/components/Auth/Auth'
import { SlidesShow } from '../../Core/components/SlidesShow/SlidesShow'
import { PhotoSlide } from '../components/PhotoSlide/PhotoSlide'
import { InputSlide } from '../components/InputSlide/InputSlide'
import { MappingSlide } from '../components/MappingSlide/MappingSlide'
import { ListSlide } from '../components/ListSlide/ListSlide'

let setCallback: () => void

export const AirInsightView: FunctionComponent<RouteComponentProps> = () => {
    const [round, setRound] = useState(0)

    const [index, setIndex] = useState<number>(1)
    const [step, setStep] = useState<number>(3)

    const [dataUri, setDataUri] = useState<string>()
    const [id, setId] = useState<string>()
    const [devEUI, setDevEUI] = useState<string>()
    const [code, setCode] = useState<string>()
    const [isMapping, setIsMapping] = useState<boolean>(false)

    const cleanup = () => {
        setIsMapping(false)
        setStep(3)
        setIndex(1)
        setRound(round + 1)

        setDataUri(undefined)
        setId(undefined)
        setDevEUI(undefined)
        setCode(undefined)

        //eslint-disable-next-line @typescript-eslint/no-empty-function
        setCallback = () => {}
    }

    return (
        <Page>
            <Auth>
                <Body>
                    <SlidesShow
                        onIndexChange={(index: number) => {
                            setIndex(index % step)
                        }}
                        index={index}
                    >
                        <ListSlide key={`ls_${round}`} isActive={index === 0} />
                        <PhotoSlide
                            key={`ps_${round}`}
                            isDisable={isMapping}
                            isActive={index === 1}
                            onTakePhoto={(dataUri?: string) => {
                                setDataUri(dataUri)
                                setStep(3)
                                setIndex(dataUri ? 2 : 1)
                            }}
                        />
                        <InputSlide
                            key={`is_${round}`}
                            isDisable={isMapping}
                            isActive={index === 2}
                            dataUri={dataUri}
                            onMap={(id, code, devEUI, callback) => {
                                setIsMapping(true)
                                setStep(4)
                                setIndex(3)
                                setId(id)
                                setDevEUI(devEUI)
                                setCode(code)
                                setCallback = callback
                            }}
                        />
                        <MappingSlide
                            isActive={index === 3}
                            devEUI={devEUI}
                            id={id}
                            code={code}
                            callback={setCallback}
                            onDone={cleanup}
                        />
                    </SlidesShow>
                </Body>
            </Auth>
        </Page>
    )
}
