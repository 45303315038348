import classNames from 'classnames'
import React, { FunctionComponent } from 'react'
import styles from './Logo.module.scss'
import logo from '../../../../assets/logo.png'

export interface LogoProps {
    className?: string
}

export const Logo: FunctionComponent<LogoProps> = ({ className }) => {
    return <img src={logo} className={classNames(styles.logo, className)} alt={'Unica'} />
}
