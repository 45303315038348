import React, { FunctionComponent, useState } from 'react'
import styles from './ResetPassword.module.scss'
import { Logo } from '../../../Core/components/Logo/Logo'
import { Button, ButtonType } from '../../../Core/components/Button/Button'
import { useHistory } from 'react-router-dom'
import { routes } from '../../../../routes'
import { authService } from '@unicaiot/unica-iot-gallery-core'

export interface ResetPasswordParams {
    token: string
}

export const ResetPassword: FunctionComponent<ResetPasswordParams> = ({ token }) => {
    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string>()

    const history = useHistory()

    const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault()

            if (password1 !== password2) {
                setErrorMessage('Passwords are not equil.')
                return
            }

            setLoading(true)

            const result = await authService.postUpdatePassword(token, password1, password2)

            setLoading(false)

            if (result.status === 200 || result.status === 204) {
                history.push(routes.login)
            } else {
                setErrorMessage(result.statusText)
            }
        } catch (e: any) {
            setLoading(false)
            setErrorMessage(e.message)
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.formContainer}>
                <form onSubmit={handleOnSubmit}>
                    <Logo className={styles.logo} />
                    <input
                        type="password"
                        placeholder="Password"
                        onChange={e => setPassword1(e.target.value)}
                        value={password1}
                        required={true}
                    />
                    <input
                        type="password"
                        placeholder="Confirm Password"
                        onChange={e => setPassword2(e.target.value)}
                        value={password2}
                        required={true}
                    />
                    <Button submit={true} type={ButtonType.primary} className={styles.buttom} loading={loading}>
                        Change
                    </Button>
                    {errorMessage && <p>{errorMessage}</p>}
                </form>
            </div>
        </div>
    )
}
