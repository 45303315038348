import React, { FunctionComponent } from 'react'
import { Page } from '../../Core/components/Layout/Page/Page'
import { RouteComponentProps } from 'react-router-dom'
import { Login } from '../components/Login/Login'

export interface LoginViewState {
    tenantId: string
}

export const LoginView: FunctionComponent<
    RouteComponentProps<Record<string, string>, Record<string, string>, LoginViewState>
> = params => {
    return (
        <Page>
            <Login tenantId={params.location?.state?.tenantId} />
        </Page>
    )
}
