import { client, ResponsePromise } from '@unicaiot/unica-iot-gallery-core'
import { config } from '../../../config'
import { QRClient, QRMap, UploadResponse } from './types'

export interface IAirInsightService {
    get: () => ResponsePromise<QRMap[]>
    delete: (id: string) => ResponsePromise
    post: (item: QRMap) => ResponsePromise
    put: (item: QRMap) => ResponsePromise
    getPng: (id: string, qrClient?: QRClient) => ResponsePromise<ArrayBuffer>
    getPdf: (id: string, qrClient?: QRClient) => ResponsePromise<ArrayBuffer>
    upload: (dataUrl: string) => ResponsePromise<UploadResponse>
    getId: (code: string) => ResponsePromise<string>
}

export const airInsightService: IAirInsightService = {
    get() {
        return client.get(config.mapping.get)
    },

    delete(id: string) {
        return client.delete(config.mapping.delete, { params: { id } })
    },

    put(item: QRMap) {
        return client.put(config.mapping.put, { id: item.id, code: item.value, parameters: item.parameters })
    },

    post(item: QRMap) {
        return client.post(config.mapping.post, { code: item.value, parameters: item.parameters })
    },

    getPng(id: string, qrClient?: QRClient) {
        return client.get(config.qr.png, {
            params: { id, client: qrClient ? QRClient[qrClient] : null },
            responseType: 'arraybuffer',
        })
    },

    getPdf(id: string, qrClient?: QRClient) {
        return client.get(config.qr.pdf, {
            params: { id, client: qrClient ? QRClient[qrClient] : null },
            responseType: 'arraybuffer',
        })
    },

    upload(dataUrl: string) {
        const dataURLtoFile = (dataUrl: string, filename: string) => {
            const dataUrlSegments = dataUrl.split(',')
            const mimeSegments = dataUrlSegments[0]?.match(/:(.*?);/)
            const mime = mimeSegments ? mimeSegments[1] : undefined
            const bStr = atob(dataUrlSegments[1])
            let n = bStr.length
            const u8arr = new Uint8Array(n)
            while (n) {
                u8arr[n - 1] = bStr.charCodeAt(n - 1)
                n -= 1
            }
            return new File([u8arr], filename, { type: mime })
        }

        const file = dataURLtoFile(dataUrl, 'file')
        const data = new FormData()
        data.append('formFile', file, file.name)

        return client.post(config.mapping.upload, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    },

    getId(code: string) {
        return client.get(config.mapping.codeToId, { params: { code } })
    },
}
