import { QRClient, QRMap, UploadResponse } from './types'
import { airInsightService } from './service'
import { RequestStatus, useRequest } from '@unicaiot/unica-iot-gallery-core'

export const useGet = (): RequestStatus<QRMap[] | undefined> => {
    const getProsmise = () => airInsightService.get()

    return useRequest(getProsmise, [])
}

export const useGetPng = (id: string, client?: QRClient): RequestStatus<ArrayBuffer | undefined> => {
    const getProsmise = () => airInsightService.getPng(id, client)

    return useRequest(getProsmise, [id, client])
}

export const useUpload = (dataUri?: string): RequestStatus<UploadResponse | undefined> => {
    const getProsmise = () => (dataUri ? airInsightService.upload(dataUri) : undefined)

    return useRequest(getProsmise, [dataUri])
}

export const useGetId = (code?: string): RequestStatus<string | undefined> => {
    const getProsmise = () => (code ? airInsightService.getId(code) : undefined)

    return useRequest(getProsmise, [code])
}
