import { cancel, client, ResponsePromise, withCancelTocken } from '@unicaiot/unica-iot-gallery-core'
import { config } from '../../../config'
import { HEntity, HEntityOverview } from './types'

export interface IHSService {
    postUpsert: (entity: HEntity) => ResponsePromise
    getOverview: (filter: string) => ResponsePromise<HEntityOverview[]>
    delete: (id: string) => ResponsePromise
}

export const hsService: IHSService = {
    postUpsert(entity: HEntity) {
        return client.post(config.metadata.upsert, entity)
    },

    getOverview(filter: string) {
        cancel()

        return client.get<HEntityOverview[]>(config.metadata.filter, {
            params: { filter },
            cancelToken: withCancelTocken(),
        })
    },

    delete(id: string) {
        return client.delete(config.metadata.delete, { params: { id } })
    },
}
