export interface QRMap {
    id: string
    value: string
    parameters?: {
        //eslint-disable-next-line
        [key: string]: any
    }
}

export enum QRClient {
    Co2,
    Co2Admin,
    Mp,
}

export interface UploadResponse {
    devEuI: string
    texts: string[]
    code: {
        code: string
        id?: string
    }
}
