import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'core-js/web/url-search-params'

import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.scss'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { RootView } from './RootView'

import { Provider, positions } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'

const App: React.FunctionComponent = () => (
    <Provider template={AlertTemplate} timeout={4000} position={positions.BOTTOM_CENTER}>
        <Router>
            <Route path="/" component={RootView} />
        </Router>
    </Provider>
)

ReactDOM.render(<App />, document.getElementById('root'))
