import React, { FunctionComponent, useEffect, useState } from 'react'
import { Slide } from '../../../Core/components/SlidesShow/Slide'
import { hsService } from '../../../Haystack/services/service'
import { DisableTag, EntityType, HEntity, TagType } from '../../../Haystack/services/types'
import styles from './MappingSlide.module.scss'
import { v4 as uuidv4 } from 'uuid'
import { airInsightService } from '../../services/service'
import { Button, ButtonType } from '../../../Core/components/Button/Button'
import { ActivityIndicator, ActivityIndicatorEnumType, PageError } from '@unicaiot/unica-iot-gallery-core'
import { useAlert } from 'react-alert'

interface Props {
    isActive?: boolean
    id?: string
    devEUI?: string
    code?: string
    callback?: () => void
    onDone?: () => void
}

export const MappingSlide: FunctionComponent<Props> = ({ isActive = false, id, devEUI, code, callback, onDone }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [isDone, setIsDone] = useState(false)
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [error, setError] = useState<any>()

    const alert = useAlert()

    useEffect(() => {
        ;(async function map() {
            try {
                if (id && devEUI) {
                    setIsLoading(true)
                    setIsDone(false)

                    const existed = await hsService.getOverview(
                        `sensorId == "urn:dev:DEVEUI:${devEUI}:" and type=="CO2"`
                    )
                    if (existed.data?.length > 0) {
                        throw new PageError(`${devEUI} is already exist.`)
                    }

                    const defaultGroup = await hsService.getOverview('airInsights and default and (equip or site)')

                    if (defaultGroup.data?.length > 2) {
                        throw new PageError(`Ambiguous default site and equip. ${defaultGroup.data.length} are found.`)
                    }

                    let defaultSite = defaultGroup.data?.find(e => e.type === EntityType[EntityType.site])
                    let defaultEquip = defaultGroup.data?.find(e => e.type === EntityType[EntityType.equip])

                    let defaultSetup = false

                    if (!defaultSite) {
                        defaultSetup = true

                        defaultSite = {
                            id: uuidv4(),
                            dis: 'Air Insights Default Building',
                            type: 'site',
                        }

                        await hsService.postUpsert({
                            id: defaultSite.id,
                            tags: [
                                {
                                    name: 'dis',
                                    type: TagType.HStr,
                                    value: defaultSite.dis || '',
                                },
                                {
                                    name: defaultSite.type,
                                    type: TagType.HMarker,
                                    value: 'marker',
                                },
                                {
                                    name: 'airInsights',
                                    type: TagType.HMarker,
                                    value: 'marker',
                                },
                                {
                                    name: 'default',
                                    type: TagType.HMarker,
                                    value: 'marker',
                                },
                            ],
                        })
                    }

                    if (!defaultEquip) {
                        defaultSetup = true

                        defaultEquip = {
                            id: uuidv4(),
                            dis: 'Air Insights Default Room',
                            type: 'equip',
                        }

                        await hsService.postUpsert({
                            id: defaultEquip.id,
                            tags: [
                                {
                                    name: 'dis',
                                    type: TagType.HStr,
                                    value: defaultEquip.dis || '',
                                },
                                {
                                    name: defaultEquip.type,
                                    type: TagType.HMarker,
                                    value: 'marker',
                                },
                                {
                                    name: 'siteRef',
                                    type: TagType.HRef,
                                    value: defaultSite.id,
                                },
                                {
                                    name: 'airInsights',
                                    type: TagType.HMarker,
                                    value: 'marker',
                                },
                                {
                                    name: 'default',
                                    type: TagType.HMarker,
                                    value: 'marker',
                                },
                            ],
                        })
                    }

                    const getPoint: (
                        type: 'CO2' | 'temperature' | 'humidity',
                        unit: 'ppm' | 'C' | '%RH',
                        postfix: '' | '-Temp' | '-Humidity'
                    ) => HEntity = (
                        type: 'CO2' | 'temperature' | 'humidity',
                        unit: 'ppm' | 'C' | '%RH',
                        postfix: '' | '-Temp' | '-Humidity'
                    ) => {
                        return {
                            id: uuidv4(),
                            tags: [
                                {
                                    name: 'dis',
                                    type: TagType.HStr,
                                    value: devEUI ? `${devEUI}${postfix}` : '',
                                },
                                {
                                    name: 'point',
                                    type: TagType.HMarker,
                                    value: 'marker',
                                },
                                {
                                    name: 'airInsights',
                                    type: TagType.HMarker,
                                    value: 'marker',
                                },
                                {
                                    name: 'siteRef',
                                    type: TagType.HRef,
                                    value: defaultSite!.id,
                                },
                                {
                                    name: 'equipRef',
                                    type: TagType.HRef,
                                    value: defaultEquip!.id,
                                },
                                {
                                    name: 'tz',
                                    type: TagType.HStr,
                                    value: 'Amsterdam',
                                },
                                {
                                    name: 'kind',
                                    type: TagType.HStr,
                                    value: 'Number',
                                },

                                {
                                    name: 'his',
                                    type: TagType.HMarker,
                                    value: 'marker',
                                },
                                {
                                    name: 'unit',
                                    type: TagType.HStr,
                                    value: unit,
                                },
                                {
                                    name: 'type',
                                    type: TagType.HStr,
                                    value: type,
                                },
                                {
                                    name: 'sensorId',
                                    type: TagType.HStr,
                                    value: `urn:dev:DEVEUI:${devEUI}:`,
                                },
                                {
                                    name: DisableTag,
                                    type: TagType.HBool,
                                    value: 'false',
                                },
                                {
                                    name: 'default',
                                    type: TagType.HMarker,
                                    value: 'marker',
                                },
                            ],
                        }
                    }

                    const co2 = getPoint('CO2', 'ppm', '')

                    await hsService.postUpsert(co2)

                    try {
                        airInsightService.put({
                            id: id,
                            value: co2.id,
                            parameters: {
                                devEUI: devEUI,
                                code: code,
                            },
                        })
                    } catch (error) {
                        hsService.delete(co2.id)
                        throw new PageError(error)
                    }

                    try {
                        const temp = getPoint('temperature', 'C', '-Temp')

                        await hsService.postUpsert(temp)
                    } catch {
                        alert.error('A temparature record has not been created. Please review and create manually.')
                    }

                    try {
                        const humidity = getPoint('humidity', '%RH', '-Humidity')

                        await hsService.postUpsert(humidity)
                    } catch {
                        alert.error('A humidity record has not been created. Please review and create manually.')
                    }

                    if (defaultSetup) {
                        alert.info('It is the initial setup. It will take up to 30s to complete. Please be patient.', {
                            timeout: 0,
                        })

                        await new Promise(resolve => setTimeout(resolve, 15000))
                    }

                    setIsLoading(false)
                    callback && callback()
                    setIsDone(true)
                }
            } catch (error) {
                setError(error)
            }
        })()
    }, [id, devEUI, code, callback, alert])

    if (error) {
        throw error
    }

    return (
        <Slide isActive={isActive}>
            {isLoading && <ActivityIndicator className={styles.loader} size={ActivityIndicatorEnumType.large} />}
            {isDone && (
                <>
                    <div className={styles.text}>Success!</div>
                    <span className={styles.link}>
                        <Button type={ButtonType.noStyling} onClick={onDone}>
                            Start again
                        </Button>
                    </span>
                </>
            )}
        </Slide>
    )
}
