import { routes } from './routes'

const regexpEnvPlaceholder = /^{{[A-Z_0-9]+}}$/

function getEnv(name: string, defaultValue: string = ''): string {
    if (window.__ENV && window.__ENV[name] && !window.__ENV[name].match(regexpEnvPlaceholder)) {
        return window.__ENV[name]
    }
    return process.env['REACT_APP_' + name] || defaultValue
}

export interface OAuthConfig {
    basePath: string
    token: string
    user: string
    revocation: string
    client_secret: string
    client_id: string

    resetPassword: string
    updatePassword: string

    api_key: string

    persistence: string
}

export interface Config {
    defaultPath: string
    basePath: string
    instrumentationKey: string

    oauth: OAuthConfig

    metadata: {
        upsert: string
        filter: string
        delete: string
    }

    mapping: {
        get: string
        delete: string
        post: string
        put: string
        upload: string
        codeToId: string
    }

    qr: {
        png: string
        pdf: string
    }
}

export const config: Config = {
    defaultPath: getEnv('DEFAULT_PATH', routes.index),
    basePath: getEnv('BASE_PATH', 'https://iot-unica-canaries-management-api.azurewebsites.net/'),

    instrumentationKey: getEnv('APPINSIGHTS_INSTRUMENTATIONKEY'),

    oauth: {
        basePath: getEnv('OAUTH_BASE_PATH', 'https://iot-unica-canaries-identityservice.azurewebsites.net/'),
        client_secret: getEnv('OAUTH_CLIENT_SECRET'),
        client_id: getEnv('OAUTH_CLIENT_ID', 'managementClient'),
        api_key: getEnv('OAUTH_API_KEY'),
        token: 'connect/token',
        user: 'connect/userinfo',
        revocation: 'connect/revocation',
        resetPassword: 'api/passwords/create-reset-token',
        updatePassword: 'api/passwords/update',
        persistence: 'api/persistence',
    },

    metadata: {
        upsert: '/api/metadata/upsert',
        filter: '/api/metadata',
        delete: '/api/metadata',
    },

    mapping: {
        get: 'mapping/co2App',
        delete: 'mapping/co2App',
        post: 'mapping/co2App',
        put: 'mapping/co2App',
        upload: 'mapping/co2App/upload',
        codeToId: 'mapping/co2App/code-to-id',
    },

    qr: {
        png: 'qr/co2App/png',
        pdf: 'qr/co2App/pdf',
    },
}
