/* eslint-disable  @typescript-eslint/no-explicit-any */

import React, { FunctionComponent } from 'react'
import { config } from '../../../config'
import styles from './ErrorView.module.scss'
import { Robot } from '../components/Robot/Robot'

export interface ErrorViewParams {
    status?: any
}

export const ErrorView: FunctionComponent<ErrorViewParams> = ({ status }) => {
    return status !== 401 ? (
        <div className={styles.container}>
            <div className={styles.context}>
                <Robot />
                <div className={styles.text}>
                    Ops! Something went wrong! But don&apos;t worry we will help you to find your way out.{' '}
                </div>
                <a href={config.defaultPath} className={styles.link}>
                    Go Home
                </a>
            </div>
        </div>
    ) : (
        <></>
    )
}
