import React, { FunctionComponent, useEffect, useState } from 'react'
import styles from './Login.module.scss'
import { Logo } from '../../../Core/components/Logo/Logo'
import { Button, ButtonType } from '../../../Core/components/Button/Button'
import { useHistory } from 'react-router-dom'
import { config } from '../../../../config'
import { ActivityIndicator, ActivityIndicatorEnumType, authService } from '@unicaiot/unica-iot-gallery-core'

export interface LoginParams {
    tenantId?: string
}

export const Login: FunctionComponent<LoginParams> = ({ tenantId }) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string>()
    const [isResetPassword, setIsResetPassword] = useState(false)
    const [isRefreshing, setIsRefreshing] = useState(tenantId ? true : false)

    const history = useHistory()

    useEffect(() => {
        if (tenantId) {
            ;(async function postRefresh() {
                const result = await authService.postRefresh(tenantId)

                if (result.status === 200) {
                    history.goBack()
                } else {
                    setIsRefreshing(false)
                }
            })()
        }
    }, [tenantId, history])

    const handleOnLogin = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault()

            setLoading(true)

            const result = await authService.postLogin(email, password, tenantId)

            setLoading(false)

            if (result.status === 200) {
                history.push(config.defaultPath)
            } else {
                setErrorMessage(result.statusText)
            }
        } catch (e: any) {
            setLoading(false)

            let message = undefined
            switch (e.response?.status) {
                case 401:
                    message = 'Invalid username and password.'
                    break
                default:
                    message = e.message
            }
            setErrorMessage(message)
        }
    }

    const handleOnReset = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault()

            setLoading(true)

            const result = await authService.postResetPassword(email)

            if (result.status === 200) {
                setIsResetPasswordAndCleanUp(false)
            } else {
                setErrorMessage(result.statusText)
            }
        } catch (e: any) {
            setErrorMessage(e.message)
        } finally {
            setLoading(false)
        }
    }

    const setIsResetPasswordAndCleanUp = (isResetPassword: boolean) => {
        setIsResetPassword(isResetPassword)
        setEmail('')
        setPassword('')
    }

    return (
        <div className={styles.container}>
            {isRefreshing ? (
                <ActivityIndicator size={ActivityIndicatorEnumType.large} />
            ) : (
                <div className={styles.formContainer}>
                    <form onSubmit={isResetPassword ? handleOnReset : handleOnLogin}>
                        <Logo className={styles.logo} />
                        <input
                            type="email"
                            placeholder="Email"
                            onChange={e => setEmail(e.target.value)}
                            value={email}
                        />
                        {!isResetPassword && (
                            <input
                                type="password"
                                placeholder="Password"
                                onChange={e => setPassword(e.target.value)}
                                value={password}
                            />
                        )}
                        {!isResetPassword && (
                            <Button
                                className={styles.link}
                                onClick={() => setIsResetPasswordAndCleanUp(true)}
                                type={ButtonType.noStyling}
                            >
                                Forget your password?
                            </Button>
                        )}
                        <Button submit={true} type={ButtonType.primary} className={styles.buttom} loading={loading}>
                            {isResetPassword ? 'Reset' : 'Sign In'}
                        </Button>
                        {isResetPassword && (
                            <Button
                                className={styles.link}
                                onClick={() => setIsResetPasswordAndCleanUp(false)}
                                type={ButtonType.noStyling}
                            >
                                Back
                            </Button>
                        )}
                        {errorMessage && <p>{errorMessage}</p>}
                    </form>
                </div>
            )}
        </div>
    )
}
