import React, { FunctionComponent } from 'react'
import { Auth as GlobalAuth } from '@unicaiot/unica-iot-gallery-core'
import { Header } from '../../../Core/components/Layout/Header/Header'

export const Auth: FunctionComponent = ({ children }) => {
    return (
        <GlobalAuth
            content={(user, onLogout) => {
                return (
                    <>
                        <Header user={user} onLogout={onLogout} />
                        {children}
                    </>
                )
            }}
        />
    )
}
