import styles from './Page.module.scss'

import React, { CSSProperties } from 'react'
import classNames, { Value } from 'classnames' // eslint-disable-next-line

interface Props {
    style?: CSSProperties
    className?: Value
}

export const Page: React.FunctionComponent<Props> = ({ className, style, children }) => {
    return (
        <div style={style} className={classNames(styles.container, className)}>
            {children}
        </div>
    )
}
