export enum EntityType {
    site = 1,
    equip = 2,
    point = 3,
}

export type EntityTypeValues = 'site' | 'equip' | 'point'

export interface HTag {
    name: string
    type: TagType | string
    value: string
}

export interface HEntity {
    tags?: HTag[]
    id: string
    dis?: string
}

export interface HEntityOverview {
    id: string
    dis?: string
    type: EntityTypeValues
}

export enum TagType {
    HMarker = 'HMarker',
    HRemove = 'HRemove',
    HStr = 'HStr',
    HRef = 'HRef',
    HNum = 'HNum',
    HBool = 'HBool',
    HDate = 'HDate',
    HTime = 'HTime',
    HDateTime = 'HDateTime',
}

export const DisableTag = 'i_streaming_enabled'
