import classNames, { Value } from 'classnames' // eslint-disable-next-line
import React, { FunctionComponent, Children } from 'react'
import styles from './SlidesShow.module.scss'

interface Props {
    className?: Value
    onIndexChange?: (index: number) => void
    index?: number
}

export const SlidesShow: FunctionComponent<Props> = ({ index = 0, onIndexChange, className, children }) => {
    const count = Children.count(children)

    const setIndexInternal = (i: number) => {
        onIndexChange && onIndexChange(Math.abs(i % count))
    }

    return (
        <div className={classNames(styles.container, className)}>
            <div className={styles.slidesContainer}>{children}</div>
            <button className={styles.prev} onClick={() => setIndexInternal(count + index - 1)}>
                &#10094;
            </button>
            <button className={styles.next} onClick={() => setIndexInternal(index + 1)}>
                &#10095;
            </button>
            <div className={styles.dotsCotainer}>
                {Children.map(children, (_, i) => {
                    return (
                        <span
                            className={classNames(styles.dot, {
                                [styles.isActive]: index === i,
                            })}
                            onClick={() => setIndexInternal(i)}
                        />
                    )
                })}
            </div>
        </div>
    )
}
