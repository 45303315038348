import React, { FunctionComponent } from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { config } from './config'
import { routes } from './routes'
import { LoginView } from './domain/Login/Views/LoginView'
import { ResetPasswordView } from './domain/Login/Views/ResetPasswordView'
import { AirInsightView } from './domain/AirInsight/Views/AirInsightView'
import { TelemetryProvider, Shield, withLogin, useResize } from '@unicaiot/unica-iot-gallery-core'
import { ErrorView } from './domain/Errors/Views/ErrorView'

withLogin()

const TelemetryProviderWithRouter = withRouter(TelemetryProvider)

export const RootView: FunctionComponent = () => {
    useResize()

    return (
        <Shield
            getErrorView={() => {
                return <ErrorView />
            }}
        >
            <TelemetryProviderWithRouter instrumentationKey={config.instrumentationKey}>
                <Switch>
                    <Route exact={true} path={routes.login} component={LoginView} />
                    <Route exact={true} path={routes.resetPassword} component={ResetPasswordView} />
                    <Route exact={true} path={routes.index} component={AirInsightView} />
                    <Redirect to={config.defaultPath} />
                </Switch>
            </TelemetryProviderWithRouter>
        </Shield>
    )
}
