import React, { FunctionComponent, useEffect, useState } from 'react'
import { Slide } from '../../../Core/components/SlidesShow/Slide'
import { useGetId, useUpload } from '../../services/hooks'
import styles from './InputSlide.module.scss'
import { NIL as NIL_UUID } from 'uuid'
import classNames from 'classnames'
import { Row } from '../../../Core/components/Layout/Row/Row'
import { Button, ButtonType } from '../../../Core/components/Button/Button'
import { useGetOverview } from '../../../Haystack/services/hooks'
import {
    ActivityIndicator,
    ActivityIndicatorEnumType,
    PageError,
    useDebounce,
    useUser,
} from '@unicaiot/unica-iot-gallery-core'

interface Props {
    isActive?: boolean
    dataUri?: string
    isDisable?: boolean
    onMap: (id: string, code: string, devEUI: string, callback: () => void) => void
}

export const InputSlide: FunctionComponent<Props> = ({ isActive = false, dataUri, onMap, isDisable = false }) => {
    const user = useUser()

    const [devEUI, setDevEUI] = useState<string>('')
    const [code, setCode] = useState<string>('')
    const [isCodeValid, setIsCodeValid] = useState<boolean>()
    const [isDevEUIValid, setIsDevEUIValid] = useState<boolean>()

    const [isMapping, setIsMapping] = useState<boolean>(false)

    const debouncedCode = useDebounce(code, 1000)
    const debounceDevEUI = useDebounce(devEUI, 1000)

    useEffect(() => {
        setDevEUI('')
        setCode('')
        setIsCodeValid(undefined)
        setIsDevEUIValid(undefined)
        setIsMapping(false)
    }, [dataUri])

    const uResult = useUpload(dataUri)

    if (uResult.error) {
        throw new PageError(uResult.error)
    }

    const idResult = useGetId(debouncedCode)

    if (idResult.error) {
        throw new PageError(idResult.error)
    }

    const dResult = useGetOverview(
        debounceDevEUI ? `sensorId == "urn:dev:DEVEUI:${debounceDevEUI}:" and type=="CO2"` : undefined
    )

    if (dResult.error) {
        throw new PageError(dResult.error)
    }

    useEffect(() => {
        if (uResult.data) {
            uResult.data?.devEuI && setDevEUI(uResult.data?.devEuI)
            uResult.data?.code?.code && setCode(uResult.data?.code?.code)
            ;(!uResult.data.code.id || uResult.data.code.id === NIL_UUID) && setIsCodeValid(false)
        }
    }, [uResult.data])

    useEffect(() => {
        if (idResult.data) {
            setIsCodeValid(idResult.data !== NIL_UUID ? true : false)
        }
    }, [idResult.data])

    useEffect(() => {
        if (dResult.data) {
            setIsDevEUIValid(dResult.data.length > 0 ? false : true)
        }
    }, [dResult.data])

    return (
        <Slide isActive={isActive}>
            {uResult.loading ? (
                <ActivityIndicator className={styles.loader} size={ActivityIndicatorEnumType.large} />
            ) : (
                <>
                    <Row className={styles.tenantName}>
                        <span>{user?.Tenants.find(t => t.Id === user?.TenantId)?.Name}</span>
                    </Row>
                    <Row className={styles.inputRow}>
                        <input
                            value={code}
                            className={classNames(styles.input, {
                                [styles.isInvalid]: isCodeValid === false,
                            })}
                            placeholder={'Code'}
                            onChange={e => setCode(e.target?.value)}
                        />
                        {idResult.loading && (
                            <ActivityIndicator
                                className={styles.validadionLoader}
                                size={ActivityIndicatorEnumType.small}
                            />
                        )}
                    </Row>
                    <Row className={styles.inputRow}>
                        <input
                            value={devEUI}
                            className={classNames(styles.input, {
                                [styles.isInvalid]: isDevEUIValid === false,
                            })}
                            placeholder={'DevEUI'}
                            onChange={e => setDevEUI(e.target?.value)}
                            list={uResult.data?.texts && uResult.data?.texts.length > 0 ? 'po' : undefined}
                        />
                        {uResult.data?.texts && uResult.data?.texts.length > 0 && (
                            <datalist id="po">
                                {uResult.data?.texts?.map((t, i) => {
                                    return (
                                        <option value={t} key={`${i}`}>
                                            {t}
                                        </option>
                                    )
                                })}
                            </datalist>
                        )}
                        {idResult.loading && (
                            <ActivityIndicator
                                className={styles.validadionLoader}
                                size={ActivityIndicatorEnumType.small}
                            />
                        )}
                    </Row>

                    <Button
                        loading={isMapping}
                        className={styles.input}
                        disabled={isCodeValid !== true || isDevEUIValid !== true || isDisable}
                        type={ButtonType.primary}
                        onClick={() => {
                            setIsMapping(true)
                            onMap(idResult.data || '', code, devEUI, () => {
                                setIsMapping(false)
                            })
                        }}
                    >
                        Map
                    </Button>
                </>
            )}
        </Slide>
    )
}
