import styles from './Icon.module.scss'

import React from 'react'
import classNames, { Value } from 'classnames' // eslint-disable-next-line

export { default as Icons } from '../../../../assets/icons/iconlyData.json'

type SizeType = number | [number, number]

interface IconData {
    size: number[]
    sourcePath: string
    fileData: string
}

export interface IconType {
    name: string
    svgs: IconData[]
}

interface Props {
    icon: IconType
    className?: Value
    size?: SizeType
}

interface ParsedIcon {
    icon: string
    size: number[]
}

function parseInputWidth(size?: SizeType) {
    if (Array.isArray(size)) {
        return size[0]
    }
    return size
}

function parseInputHeight(size?: SizeType) {
    if (Array.isArray(size)) {
        return size[1]
    }
    return size
}

export const Icon: React.FunctionComponent<Props> = ({ size, icon, className }) => {
    const width = parseInputWidth(size)
    const height = parseInputHeight(size)
    const { size: bestFittedSize, icon: bestFittedIcon } = getBestFitIcon()

    return (
        <i
            className={classNames(styles.container, className)}
            style={getIconStyles(bestFittedSize)}
            dangerouslySetInnerHTML={{
                __html: bestFittedIcon,
            }}
        />
    )

    function getIconStyles(size: number[]) {
        return {
            width: width ? width : size[0],
            height: height ? height : size[1],
        }
    }

    function getBestFitIcon(): ParsedIcon {
        const sortedIcons = icon.svgs.slice().sort((a: IconData, b: IconData) => {
            return a.size[0] - b.size[0]
        })

        // Try to find (square) size match
        const bestFitIconArray = sortedIcons.filter((svg: IconData) => {
            return width === svg.size[0]
        })
        let bestFitIcon = bestFitIconArray[0]

        // Else we try to find the largest but not larger
        if (!bestFitIcon && width) {
            icon.svgs.forEach((svg: IconData) => {
                if (svg.size[0] <= width) {
                    bestFitIcon = svg
                }
            })
        }

        // Else just return the first we have (probably the only one)
        // This will allow for usage of larger icons should you choose a size under the minimum size
        if (!bestFitIcon) {
            bestFitIcon = icon.svgs[0]
        }

        return {
            size: bestFitIcon.size,
            icon: bestFitIcon.fileData,
        }
    }
}
