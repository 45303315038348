import styles from './Button.module.scss'

import React, { CSSProperties } from 'react'
import classNames, { Value } from 'classnames' // eslint-disable-next-line
import { IconType, Icon } from '../Icon/Icon'
import { ActivityIndicator } from '@unicaiot/unica-iot-gallery-core'

interface Props {
    className?: Value
    type?: ButtonType
    disabled?: boolean
    loading?: boolean
    icon?: IconType
    onClick?: () => void
    submit?: boolean
    form?: string
    highlighted?: boolean
    isWide?: boolean
    isIconButton?: boolean
    style?: CSSProperties
}

export enum ButtonType {
    primary = 'primary',
    secondary = 'secondary',
    danger = 'danger',
    cancel = 'cancel',
    noStyling = 'isNotStyled',
    tab = 'tab',
    ghost = 'ghost',
    link = 'link',
}

export const Button: React.FunctionComponent<Props> = ({
    icon,
    disabled,
    loading,
    submit,
    form,
    children,
    className,
    style,
    type,
    highlighted,
    isWide,
    isIconButton,
    onClick,
}) => {
    const button = React.createRef<HTMLButtonElement>()

    return (
        <button
            type={submit ? 'submit' : form ? 'submit' : 'button'}
            className={classNames(
                styles.container,
                {
                    [styles.isGhost]: type === ButtonType.ghost,
                    [styles.isPrimary]: type === ButtonType.primary,
                    [styles.isSecondary]: type === ButtonType.secondary,
                    [styles.isDanger]: type === ButtonType.danger,
                    [styles.isCancel]: type === ButtonType.cancel,
                    [styles.isNotStyled]: type === ButtonType.noStyling,
                    [styles.isTab]: type === ButtonType.tab,
                    [styles.isDisabled]: disabled,
                    [styles.isHighlighted]: highlighted,
                    [styles.isWide]: isWide,
                    [styles.hasIcon]: icon,
                    [styles.isIconButton]: isIconButton || (icon && !children),
                },
                className
            )}
            disabled={disabled ? disabled : loading}
            onClick={handleOnClick}
            ref={button}
            form={form}
            style={style}
        >
            <span className={styles.inner}>
                {renderLoader()}
                {children}
                {icon && <Icon icon={icon} className={styles.icon} />}
            </span>
        </button>
    )

    function renderLoader() {
        if (!loading) {
            return null
        }

        return (
            <div className={styles.loaderContainer}>
                <ActivityIndicator />
            </div>
        )
    }

    function handleOnClick() {
        if (disabled) {
            return
        }

        if (onClick) {
            onClick()
        }
    }
}
