import React, { FunctionComponent } from 'react'
import { Page } from '../../Core/components/Layout/Page/Page'
import { RouteComponentProps } from 'react-router-dom'
import { ResetPassword } from '../components/ResetPassword/ResetPassword'
import { parse } from 'query-string'
import { PageError } from '@unicaiot/unica-iot-gallery-core'

export interface Params {
    token: string
}

export const ResetPasswordView: FunctionComponent<RouteComponentProps<Params>> = params => {
    const tokenParams = parse(params.location.search)?.token

    const token = Array.isArray(tokenParams) ? tokenParams[0] : tokenParams

    if (!token) {
        throw new PageError('Tocken is null')
    }

    return (
        <Page>
            <ResetPassword token={token} />
        </Page>
    )
}
