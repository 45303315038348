import React, { FunctionComponent } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Logo } from '../../Logo/Logo'
import styles from './Header.module.scss'
import { config, IUser } from '@unicaiot/unica-iot-gallery-core'
import { Button, ButtonType } from '../../Button/Button'
import { routes } from '../../../../../routes'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import { Icon, Icons } from '../../Icon/Icon'

export const Header: FunctionComponent<{ user?: IUser; onLogout?: () => void }> = ({ user, onLogout }) => {
    const history = useHistory()

    const handleOnTenantClick = (tenantId: string) => {
        history.push(routes.login, { tenantId: tenantId })
    }

    const transcateText = (text?: string) => {
        return isMobile && text && text.length > 10 ? `${text.substring(0, 10)}...` : text
    }

    return (
        <div className={styles.header}>
            <Link to={config.defaultPath} className={styles.logoLink}>
                <Logo />
            </Link>
            {user && (
                <>
                    <div className={styles.authContainer}>
                        <p className={styles.user}>{`${user.given_name?.charAt(0).toUpperCase()}. ${
                            user.family_name
                        }`}</p>
                        {user.Tenants && user.Tenants.length > 1 ? (
                            <>
                                <span className={styles.tenantContainer}>
                                    <Button type={ButtonType.noStyling}>
                                        {transcateText(user.Tenants.find(t => t.Id === user.TenantId)?.Name)}
                                    </Button>
                                </span>
                                <div className={styles.dropdownContent}>
                                    {user.Tenants.sort((a, b) =>
                                        a.Name.toUpperCase() > b.Name.toUpperCase()
                                            ? 1
                                            : b.Name.toUpperCase() > a.Name.toUpperCase()
                                            ? -1
                                            : 0
                                    ).map(t => {
                                        return (
                                            <span
                                                className={classNames({
                                                    [styles.isSelected]: t.Id === user.TenantId,
                                                })}
                                                key={t.Id}
                                                onClick={() =>
                                                    t.Id === user.TenantId ? undefined : handleOnTenantClick(t.Id)
                                                }
                                            >
                                                {t.Name}
                                                {t.Id === user.DefaultTenantId && (
                                                    <Icon icon={Icons.star} size={[16, 16]} />
                                                )}
                                            </span>
                                        )
                                    })}
                                </div>
                                <span className={styles.logoutContainer}>
                                    <Button onClick={onLogout} type={ButtonType.noStyling}>
                                        logout
                                    </Button>
                                </span>
                            </>
                        ) : (
                            <span className={styles.logoutContainer}>
                                <Button onClick={onLogout} type={ButtonType.noStyling}>
                                    logout
                                </Button>
                            </span>
                        )}
                    </div>
                </>
            )}
        </div>
    )
}
