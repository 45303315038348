import styles from './Row.module.scss'

import React, { CSSProperties } from 'react'
import classNames, { Value } from 'classnames' // eslint-disable-next-line

interface Props {
    className?: Value
    spaceBetween?: boolean
    largeSpacing?: boolean
    smallSpacing?: boolean
    style?: CSSProperties
    stretch?: boolean
}

export const Row: React.FunctionComponent<Props> = ({
    style,
    children,
    largeSpacing,
    smallSpacing,
    spaceBetween,
    className,
    stretch,
}) => {
    return (
        <div
            style={style}
            className={classNames(styles.container, className, {
                [styles.hasSpaceBetween]: spaceBetween,
                [styles.hasLargeSpacing]: largeSpacing,
                [styles.hasSmallSpacing]: smallSpacing,
                [styles.contentStretched]: stretch,
            })}
        >
            {children}
        </div>
    )
}
