import classNames, { Value } from 'classnames' // eslint-disable-next-line
import React, { FunctionComponent } from 'react'
import styles from './SlidesShow.module.scss'

interface Props {
    className?: Value
    isActive?: boolean
}

export const Slide: FunctionComponent<Props> = ({ isActive = false, className, children }) => {
    return (
        <div
            className={classNames(`${styles.slide} ${styles.fade}`, className, {
                [styles.isActive]: isActive,
            })}
        >
            {children}
        </div>
    )
}
